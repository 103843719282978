export const RouteName = {
    main: '/',
    about: '/about',
    ourImpact: '/our-impact',
    capitalInvestment: '/our-impact/capital-investment',
    epc: '/our-impact/epc',
    renewableEnergy: '/our-impact/renewable-energy',
    industrial: '/our-impact/industrial',
    integratedTelecomunication: '/our-impact/integrated-telecomunication',
    realEstateProperty: '/our-impact/real-estate-property',
    developer: '/our-impact/developer',
    researchDevelopment: '/our-impact/reserch-development',
    businessHightlights: '/business-hightlights',
    businessPreview: '/business-preview',
    sustainability: '/sustainability',
    sustainabilityCommitment: '/sustainability-commitment',
    sustainabilityStrategic: '/sustainability-strategic',
    sustainabilityPartnerships: '/sustainability-partnerships',
    media: '/media',
    mediaPreview: '/media-preview',
    mediaMarketing: '/media-marketing',
    contact: '/contact',
    career: '/career',
    careerExplore: '/career-explore',
    careerList: '/career-list',
    login: '/login',
    // Admin
    adminDashboard: '/dashboard',
    adminBusiness: '/business',
    adminProduct: '/product',
    adminNews: '/news',
    adminQuestion: '/question',
};